<template>
  <div class="row">
    <div class="col-md-12" id="div_reload">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12" style="margin-bottom: -15px; !important;">
              <div class="col-md-7 float-sm-left" style="padding-left: 0px !important;">
                <h4 style="background-color: red; font-weight: bold; color: white; padding-left: 5px">Requested amount(BDT) without VAT</h4>
              </div>
              <div class="col-md-4 float-sm-right" style="padding-right: 0px !important;">
                <input type="text" v-model="searchQuery" class="form-control form-control mb-3" placeholder="Type here for search..." style="border: 2px solid #00000054 !important;">
              </div>
            </div>
          </div>
          <form action="">
            <div class="row mb-2">
              <div class="col-md-12">
                <div class="col-md-4" style="padding-left: 0px !important;">
                  <label for="requested_at">Expense Month <span class="custom-required">*</span></label>
                  <datepicker :format="customRequestedAtMonthFormat" v-validate="{ rules: { required:  true } }" :class="errors.has('requested_at') ? 'error-border-color' : ''" :minimumView="'month'" :maximumView="'year'" id="requested_at" v-model="transaction.requested_at"  name="requested_at" placeholder="Select month" ></datepicker>
                </div>
              </div>
            </div>
            <table class="table table-hover table-bordered">
              <thead>
              <tr>
                <th :class="{change_color: scrollPosition > 150}">COA Group</th>
                <th :class="{change_color: scrollPosition > 150}">ACC Name</th>
                <th :class="{change_color: scrollPosition > 150}">Expense At(Month)</th>
                <th :class="{change_color: scrollPosition > 150}">Requested Amount(BDT)</th>
                <th :class="{change_color: scrollPosition > 150}">Comment</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(expense, index) in resultQuery" :key="index">
                <td :class="expense.group_id % 2 === 0 ? 'tdGray' : ''">
                  {{ expense.coa_groups_name }} {{ expense.coa_sub_group1s_name ? '->' : '' }} {{ expense.coa_sub_group1s_name ? expense.coa_sub_group1s_name : '' }} {{ expense.coa_sub_group2s_name ? '->' : '' }} {{ expense.coa_sub_group2s_name ? expense.coa_sub_group2s_name : '' }}
                </td>
                <td :class="expense.group_id % 2 === 0 ? 'tdGray' : ''">{{ expense.name }}</td>
                <td :class="expense.group_id % 2 === 0 ? 'tdGray' : ''">
                  <datepicker :id="'expense_at_'+expense.id"  :name="'expense_at_' + expense.id" :clear-button="true" clear-button-icon="fa fa-times" v-model="expense.expense_at" v-validate="{ rules: { required: expense.requested_amount ? true : false} }" :class="errors.has('expense_at_'+expense.id) ? 'error-border-color' : ''" :format="customMonthFormat" :minimumView="'month'" :maximumView="'year'"  placeholder="Select month" ></datepicker>
                </td>
                <td :class="expense.group_id % 2 === 0 ? 'tdGray' : ''">
                  <input type="number" v-validate="{ max: 8, regex: /^[0-9]*$/, rules: {required: expense.expense_at ? true : false} }"  :id="'requested_amount_'+expense.id" v-model="expense.requested_amount" :name="'requested_amount_' + index" class="form-control form-control-sm text-right" >
                </td>
                <td :class="expense.group_id % 2 === 0 ? 'tdGray' : ''">
                  <input type="text" class="form-control form-control-sm"  :id="'comment_'+expense.id" v-model="expense.comment" :name="'comment' + index">
                </td>
              </tr>
              <tr>
                <th style="text-align: right" colspan="3">TOTAL</th>
                <td>
                  <input class="form-control form-control-sm text-right" v-model="totalRequestedAmount" v-validate="'required'" name="totalRequestedAmount"  id="totalRequestedAmount" readonly>
                </td>
                <td></td>
              </tr>
              <tr v-if="error_message">
                <td colspan="4" class="text-center">{{ error_message }}</td>
              </tr>
              </tbody>
            </table>
            <a-skeleton active :loading="loading"></a-skeleton>
            <a-button v-if="expenses.length > 0" class="btn btn-success waves-effect waves-light mr-2" :loading="saveTransaction" @click.prevent="update"><i class="fa fa-plus mr-1" aria-hidden="true"></i>Update Expense</a-button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'
import apiClient from '@/services/axios'
import $ from 'jquery'

export default {
  name: 'create',
  components: { Datepicker },
  data() {
    return {
      transaction: {
        requested_at: '',
      },
      expenses: [],
      expense_ids: [],
      validation_errors: {},
      loader: false,
      loading: false,
      saveTransaction: false,
      error_message: '',
      searchQuery: '',
      scrollPosition: null,
    }
  },
  computed: {
    resultQuery () {
      if (this.searchQuery) {
        const expenses = this.expenses.filter(expense =>
          expense.name.toLowerCase().includes(this.searchQuery.toLowerCase()),
        )
        const that = this
        if (that.transaction.requested_at) {
          expenses.forEach(function (expense) {
            expense.expense_at = that.transaction.requested_at
          })
        }
        return expenses
      } else {
        const expenses = this.expenses
        const that = this
        if (this.transaction.requested_at) {
          expenses.forEach(function (expense) {
            expense.expense_at = that.transaction.requested_at
          })
        }
        return expenses
      }
    },
    totalRow() {
      return this.expenses.map((expense) => {
        var subTotal = parseFloat(expense.requested_amount || 0)
        return (subTotal) || 0
      })
    },
    totalRequestedAmount() {
      let total = 0
      const totalRow = this.totalRow || []
      total = totalRow.reduce((subTotal, total) => total + subTotal || 0, 0)
      return parseFloat(total).toFixed(2) || 0
    },
  },
  mounted() {
    this.onUpdate()
    window.addEventListener('scroll', this.updateScroll)
  },
  methods: {
    customDate(date) {
      return moment(date).format('LL') || 'N/A'
    },
    customRequestedAtMonthFormat(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    customMonthFormat(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    onUpdate() {
      const requestTransactionId = this.$route.params.requested_transaction_id
      this.loading = true
      apiClient.get('api/hub/transaction/edit/' + requestTransactionId)
        .then(response => {
          this.loading = false
          if (!response.data.error) {
            const data = response.data
            this.expenses = data.expenses
            this.expense_ids = data.expense_ids
            this.transaction = data.transaction
          } else {
            this.$notification.error({
              message: response.data.message,
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    update() {
      this.$validator.validate().then(valid => {
        if (valid) {
          const transactionArray = []
          this.expense_ids.forEach(id => {
            const expenseAtDiv = '#expense_at_' + id
            const expenseAt = $(expenseAtDiv).val()

            const amountDiv = '#requested_amount_' + id
            const requestedAmount = $(amountDiv).val()

            const commentDiv = '#comment_' + id
            const comment = $(commentDiv).val()
            if (requestedAmount) {
              const transaction = {
                coa_master_id: id,
                expense_at: expenseAt,
                requested_amount: requestedAmount,
                comment: comment,
              }
              transactionArray.push(transaction)
            }
          })

          if (transactionArray.length === 0) {
            this.$notification.error({
              message: 'Insert at least 1 item',
            })
            return
          }
          this.saveTransaction = true
          const formData = new FormData()
          formData.append('transaction_items', JSON.stringify(transactionArray))
          formData.append('total_requested_amount', this.totalRequestedAmount)
          formData.append('requested_at', JSON.stringify(this.transaction.requested_at))
          const requestedTransactionId = this.$route.params.requested_transaction_id
          apiClient.post('api/hub/transaction/update/' + requestedTransactionId, formData, { headers: { 'Content-Type': 'multipart/form-data', 'Content-type': 'application/json' } })
            .then(response => {
              if (!response.data.error) {
                this.$notification.success({
                  message: response.data.message,
                })
                this.saveTransaction = false
                this.$router.push({ name: 'hubExpensePendingList' })
              } else {
                this.saveTransaction = false
                this.$notification.error({
                  message: response.data.message,
                })
              }
            }).catch(error => {
              this.saveTransaction = false
              this.show = true
              this.validation_errors = error.response.data.errors
              this.hide()
            })
        }
      })
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
    updateScroll() {
      this.scrollPosition = window.scrollY
    },
  },
}
</script>

<style scoped>
table {
  position: relative;
}

th {
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 1;
}
.change_color {
  background-color: #4B7CF3;
  color: #ffffff;
}
.error-border-color input{
  border: 1px solid #dc3545 !important;
}
.error-border-color{
  border: 1px solid #dc3545 !important;
}
.tdGray {
  background: #eee;
}
</style>
